// useFormStore.ts
import { defineStore } from 'pinia'

export const useFormStore = defineStore('form', {
  state: () => ({
    ssn: '',
    password: '',
    provider: '',
  }),

  getters: {
    getForm: (state) => state,
  },

  actions: {
    setField (payload: { field: string, value: string }) {
      this[payload.field] = payload.value
    },
    resetField (payload: { field: string }) {
      this[payload.field] = ''
    },
  },
})
