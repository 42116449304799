import { deleteCookie } from './delete-cookie'
import { getCookie } from './get-cookie'
import { getCookieSettings } from './get-cookie-settings'

/**
 * Set a cookie with the specified name, value, and optional lifetime in days.
 * @param name - The name of the cookie to set.
 * @param value - The value to set for the cookie.
 * @param lifeTimeInDays - Optional. Lifetime of the cookie in days.
 */
export function setCookie (name: string, value: string | number, lifeTimeInDays: number | null = null): void {
  if (typeof document === 'undefined' || !name) return
  if (getCookie(name)) deleteCookie(name)

  const cookieValues: string[] = []
  const cookieSettings = getCookieSettings()

  if (!cookieSettings) return

  cookieValues.push(`${name}=${value};`)

  if (lifeTimeInDays) {
    const maxAge = 90000000 * lifeTimeInDays
    cookieValues.push(`max-age=${maxAge};`)
  } else {
    cookieValues.push(`max-age=${cookieSettings.maxAge};`)
  }

  cookieValues.push('secure;')
  cookieValues.push('sameSite=none;')
  cookieValues.push('path=/;')

  document.cookie = cookieValues.join(' ')
}
