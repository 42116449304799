import { useMetrics } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

export const metrics = useMetrics({
  error: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  login_type: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  logout: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  auto_logout: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },

  fetch_eid_banks: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  eid_initiate: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  eid_secret: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  eid_collect: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  eid_cancel: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  oauth_create: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  password_initiate: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },

  fetch_customer: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
  save_customer: { type: 'counter', labelNames: ['brand', 'market', 'label'] as const },
})
