import { request } from '@/requests/request'

/**
 * @param {number} customerId
 * @param {string} email
 * @param {string} phone
 * @returns {Promise<any>}
 */
export async function update ({ customerId, email, phone }: { customerId: string, email: string, phone: string }): Promise<any> {
  return await request({
    method: 'PUT',
    url: `/service/common-gateway/api/v1/customer/${customerId}`,
    body: { email, phone },
    credentials: 'include',
  })
}
