export function isAuthError (e: any): boolean {
  const errors = [
    'JWT_INVALID',
    'JWT_EXPIRED',
    'AUTHORIZATION_ERROR',
  ]

  const errorCode: string = (e)?.errorCode || (e)?.graphQLErrors?.[0]?.data?.errorCode || e
  const errorName: string = (e)?.errorCode || (e)?.graphQLErrors?.[0]?.name || e

  return errors.includes(errorCode) || errors.includes(errorName)
}
