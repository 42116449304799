import { acceptHMRUpdate, defineStore } from 'pinia'
import requests from '@/requests'
import { isLoggedIn, hasProp } from '@/utils/helper-functions'

// stores
import { useMessagesStore } from './messages' // Adjust the import based on your project structure
import { useContentStore } from './content'
import { metrics } from '@/utils/metrics/client-metrics'
import { useRouteStore } from './route'
import { isAuthError } from '@/helpers/errors'
import { logError } from '@/utils/misc/log-error'

interface CustomerState {
  loggedIn: boolean
  loading: boolean
  id: string | null
  ssn: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
  phoneAlt: string | null
  street: string | null
  apartmentNumber: string | null
  zipCode: string | null
  city: string | null
}

interface Customer extends Partial<CustomerState> {
  id?: string | null
  ssn?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phone?: string | null
  phoneAlt?: string | null
  street?: string | null
  apartmentNumber?: string | null
  zipCode?: string | null
  city?: string | null
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    loggedIn: false,
    loading: true,
    id: null,
    ssn: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    phoneAlt: null,
    street: null,
    apartmentNumber: null,
    zipCode: null,
    city: null,
  }),

  getters: {
    getCustomer: (state: CustomerState) => state,
    getCustomerName: (state: CustomerState) => {
      if (state.firstName && state.lastName) {
        return `${state.firstName} ${state.lastName}`
      } else if (state.firstName) {
        return state.firstName
      } else {
        return useContentStore().getContent?.elements?.header?.labels?.myAccount
      }
    },
  },

  actions: {
    async fetchCustomer () {
      await metrics.fetch_customer.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'start' })

      if (!isLoggedIn()) {
        window.dispatchEvent(new window.Event('zmarta-header:logout:not-authorized'))
        await metrics.fetch_customer.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'not_logged_in' })

        this.$reset()
        // this.mutateCustomer({ loggedIn: false })
        this.mutateLoading(false)

        return false
      }

      try {
        const customer: Customer = await requests.serviceCommonGateway.customer.get()

        if (!customer?.id) {
          await metrics.fetch_customer.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_customer' })
          return false
        }

        this.mutateCustomer({
          loggedIn: !!customer?.id,
          ...customer,
        })

        const messagesStore = useMessagesStore()
        await messagesStore.resetMessages()
        await metrics.fetch_customer.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        return true
      } catch (e) {
        logError('customer/fetchCustomer', { e })
        isAuthError(e)
          ? await metrics.fetch_customer.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.fetch_customer.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        this.$reset()
        // this.mutateCustomer({ loggedIn: false })
      } finally {
        this.mutateLoading(false)
      }
    },

    resetLoading () {
      this.mutateLoading(false)
    },

    resetCustomer () {
      this.$reset()
    },

    mutateCustomer (payload: Partial<CustomerState>) {
      if (hasProp(payload, 'loggedIn')) this.loggedIn = payload.loggedIn!
      if (hasProp(payload, 'ssn')) this.ssn = payload.ssn!
      if (hasProp(payload, 'id')) this.id = payload.id!
      if (hasProp(payload, 'firstName')) this.firstName = payload.firstName!
      if (hasProp(payload, 'lastName')) this.lastName = payload.lastName!
      if (hasProp(payload, 'email')) this.email = payload.email!
      if (hasProp(payload, 'phone')) this.phone = payload.phone!
      if (hasProp(payload, 'phoneAlt')) this.phoneAlt = payload.phoneAlt!
      if (hasProp(payload, 'street')) this.street = payload.street!
      if (hasProp(payload, 'apartmentNumber')) this.apartmentNumber = payload.apartmentNumber!
      if (hasProp(payload, 'zipCode')) this.zipCode = payload.zipCode!
      if (hasProp(payload, 'city')) this.city = payload.city!
    },

    mutateLoading (loading: boolean) {
      this.loading = loading
    },
  },
  persist: !import.meta.env.SSR
    ? {
        storage: sessionStorage,
      }
    : undefined,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCustomerStore, import.meta.hot))
}
