import constants from '@/header/constants'
import { getCookie } from './get-cookie'
import { deleteCookie } from './delete-cookie'
import { setCookie } from './set-cookie'

const { UTM_PARAMS, MUTABLE_COOKIE_PARAMS, RESET_KEYWORD, GOOGLE_PARAMS } = constants
const cookieParams = [...UTM_PARAMS, ...MUTABLE_COOKIE_PARAMS]

export function setParamsAsCookies (urlString = '') {
  const params = getParamsFromUrlString(urlString)

  if (params?.get(RESET_KEYWORD) === 'true') {
    cookieParams.forEach(element => {
      deleteCookie(element)
    })
  }

  // We just want to update UTM params if every of them is unset
  const shouldSetUTM = UTM_PARAMS.every(i => !getCookie(i))

  for (const cookieKey of cookieParams) {
    const cookieValue = params?.get(cookieKey)
    const includesUtmParams = UTM_PARAMS.includes(cookieKey)
    const includesMutableCookieParams = MUTABLE_COOKIE_PARAMS.includes(cookieKey)

    if (!cookieValue) continue
    if (!(shouldSetUTM && includesUtmParams) && !includesMutableCookieParams) continue

    setCookie(cookieKey, encodeURIComponent(cookieValue))
  }
}

export function setGoogleParamsAsCookies (urlString = '') {
  const params = getParamsFromUrlString(urlString)

  for (const cookieKey of GOOGLE_PARAMS) {
    const cookieValue = params?.get(cookieKey)
    const includesGoogleParams = GOOGLE_PARAMS.includes(cookieKey)

    if (!cookieValue) continue
    if (!includesGoogleParams) continue

    setCookie(cookieKey, encodeURIComponent(cookieValue))
  }
}

function getParamsFromUrlString (urlString: string) {
  let params
  try {
    params = new URL(urlString).searchParams
  } catch {
    return
  }
  return params
}
