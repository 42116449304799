<script lang="ts" setup>
import type { Locale } from '@ocp-zmarta/content-sdk'
import { emitter } from '@/composables/eventBus'
import { useTranslationsStore } from '@/store/translations'
import { onMounted, onBeforeUnmount } from 'vue'
import { useMiscStore } from '@/store/misc'
import { useConfig } from '@/store/config'

const translationsStore = useTranslationsStore()
const miscStore = useMiscStore()

const changeLanguage = (event: Record<string, any>) => {
  const locale = event?.detail?.locale
  translationsStore.setLocale({ locale })
}

const onResize = () => {
  const width = window.innerWidth ||
      document.documentElement?.clientWidth ||
      document.body?.clientWidth

  miscStore.setMisc({ isMobile: (width <= 768) })
  miscStore.setMisc({ isTablet: (width > 768 && width <= 992) })
  miscStore.setMisc({ isDesktop: (width > 992) })
}

const goToStartPage = (locale: Locale) => {
  const l = locale || translationsStore.getLocale

  window.location.href = l === translationsStore.getDefaultLocale
    ? '/'
    : `/${l}`
}

const clearStorage = async (reload = false) => {
  if (import.meta.env.SSR) return true

  const serviceName = useConfig().getConfig?.serviceName
  window.sessionStorage.removeItem(serviceName)
  window.localStorage.removeItem(serviceName)

  if (reload) window.location.reload()
}

// Define named functions for the event listeners
const handleGoToStartPage = (locale: Locale) => {
  goToStartPage(locale)
}

const handleStorageClear = (reload: boolean) => {
  void clearStorage(reload)
}

const handleChangeLanguage = (event: Record<string, any>) => {
  changeLanguage(event)
}

onMounted(() => {
  emitter.on('go-to-start-page', handleGoToStartPage)
  emitter.on('storage:clear', handleStorageClear)

  window.addEventListener('zmarta-header:change-language', handleChangeLanguage)
  window.addEventListener('resize', onResize)

  onResize()
})

onBeforeUnmount(() => {
  emitter.off('go-to-start-page', handleGoToStartPage)
  emitter.off('storage:clear', handleStorageClear)

  window.removeEventListener('zmarta-header:change-language', handleChangeLanguage)
  window.removeEventListener('resize', onResize)
})
</script>

<template>
  <div />
</template>
