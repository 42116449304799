import { useServerHead } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

export default function useScripts ({
  isSweden = false,
  isFinland = false,
  isNorway = false,
  isZmarta = false,
  isBroker = false,
  isProduction = false,
}) {
  useServerHead(() => {
    const scripts = []

    // Zmarta Sweden
    if (isSweden && isZmarta && isProduction) {
      scripts.push({
        type: 'text/javascript',
        src: 'https://consent.cookiebot.com/uc.js?cbid=331c262e-2f29-4826-a52d-058fec8d2690',
        defer: true,
      })
      scripts.push({
        innerHTML: `
          function CookiebotCallback_OnAccept() {
            if (Cookiebot.consent.preferences) dataLayer.push({'event':'cookieconsent_preferences'});
            if (Cookiebot.consent.statistics) dataLayer.push({'event':'cookieconsent_statistics'});
            if (Cookiebot.consent.marketing) dataLayer.push({'event':'cookieconsent_marketing'});
          }
        `,
        type: 'text/javascript',
      })
    }

    // Zmarta Finland
    if (isFinland && isZmarta && isProduction) {
      scripts.push({
        type: 'text/javascript',
        src: 'https://consent.cookiebot.com/uc.js?cbid=91490bf6-aa44-4cf8-82b2-86b161236965',
        defer: true,
      })
      scripts.push({
        innerHTML: `
          function CookiebotCallback_OnAccept() {
            if (Cookiebot.consent.preferences) dataLayer.push({'event':'cookieconsent_preferences'});
            if (Cookiebot.consent.statistics) dataLayer.push({'event':'cookieconsent_statistics'});
            if (Cookiebot.consent.marketing) dataLayer.push({'event':'cookieconsent_marketing'});
          }
        `,
        type: 'text/javascript',
      })
    }

    // Zmarta Norway
    if (isNorway && isZmarta && isProduction) {
      scripts.push({
        type: 'text/javascript',
        src: 'https://consent.cookiebot.com/uc.js?cbid=7546b09d-7e36-4fa2-8f2c-c64f7cb22190',
        defer: true,
      })
      scripts.push({
        innerHTML: `
          function CookiebotCallback_OnAccept() {
            if (Cookiebot.consent.preferences) dataLayer.push({'event':'cookieconsent_preferences'});
            if (Cookiebot.consent.statistics) dataLayer.push({'event':'cookieconsent_statistics'});
            if (Cookiebot.consent.marketing) dataLayer.push({'event':'cookieconsent_marketing'});
          }
        `,
        type: 'text/javascript',
      })
    }

    // Freedom Finance (Sweden)
    if (isSweden && isBroker && isProduction) {
      scripts.push({
        type: 'text/javascript',
        src: 'https://consent.cookiebot.com/uc.js?cbid=628e1df0-6019-4553-9868-d98f0b27e9d7',
        defer: true,
      })
      scripts.push({
        innerHTML: `
          function CookiebotCallback_OnAccept() {
            if (Cookiebot.consent.preferences) dataLayer.push({'event':'cookieconsent_preferences'});
            if (Cookiebot.consent.statistics) dataLayer.push({'event':'cookieconsent_statistics'});
            if (Cookiebot.consent.marketing) dataLayer.push({'event':'cookieconsent_marketing'});
          }
        `,
        type: 'text/javascript',
      })
    }

    // Global captcha script (Production/Non-Production)
    /* if (isProduction) {
      scripts.push({
        type: 'text/javascript',
        src: 'https://fcac44fe6cfe.edge.captcha-sdk.awswaf.com/fcac44fe6cfe/jsapi.js',
        defer: true,
      })
    } else {
      scripts.push({
        type: 'text/javascript',
        src: 'https://80346cab26a9.eu-west-1.captcha-sdk.awswaf.com/80346cab26a9/jsapi.js',
        defer: true,
      })
    } */

    return {
      script: scripts,
    }
  })
}
