import { defineStore, acceptHMRUpdate } from 'pinia'

interface MiscState {
  isMobile: boolean | null
  isTablet: boolean | null
  isDesktop: boolean | null
  menuIsVisible: boolean
  loginIsVisible: boolean
}

export const useMiscStore = defineStore('misc', {
  state: (): MiscState => ({
    isMobile: null,
    isTablet: null,
    isDesktop: null,
    menuIsVisible: false,
    loginIsVisible: false,
  }),

  getters: {
    getMisc: (state): MiscState => state,
    getIsMobile: (state) => state.isMobile,
    getIsTablet: (state) => state.isTablet,
    getIsDesktop: (state) => state.isDesktop,
    showMenu: (state) => state.menuIsVisible,
    showLogin: (state) => state.loginIsVisible,
  },

  actions: {
    setMisc (payload: Partial<MiscState>) {
      if ('isMobile' in payload) {
        this.isMobile = payload.isMobile!
      }
      if ('isTablet' in payload) {
        this.isTablet = payload.isTablet!
      }
      if ('isDesktop' in payload) {
        this.isDesktop = payload.isDesktop!
      }
      if ('menuIsVisible' in payload) {
        this.menuIsVisible = payload.menuIsVisible!
      }
      if ('loginIsVisible' in payload) {
        this.loginIsVisible = payload.loginIsVisible!
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMiscStore, import.meta.hot))
}
