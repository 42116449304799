import { request } from '../request'

/**
 * @param {string} type
 * @param {string} name
 * @param {object} data
 * @returns {Promise<any>}
 */
export async function log (type: string, name: string, data: Record<string, any>): Promise<any> {
  return await request({
    method: 'POST',
    url: '/service/site-navigation/header/log',
    body: {
      type,
      name,
      data,
    },
  })
}
