import { request } from '@/requests/request'

/**
 * @param {string} url
 * @returns {Promise<any>}
 */
export async function pageView ({ url }: { url: string }): Promise<any> {
  return await request({
    method: 'POST',
    url: '/service/common-gateway/api/v1/tracking-events/page-view',
    body: { url },
    credentials: 'include',
  })
}
