/**
 * Get the settings for a specific cookie type or default settings if not found.
 * @param type - Optional. The type of cookie settings to retrieve.
 * @returns The settings object for the specified type or default settings if not found.
*/

const settings = {
  default: { maxAge: 90000000 * 30, httpOnly: false },
  op_user_id: { maxAge: 90000000 * 60, httpOnly: false },
  access_token: { maxAge: 90000000 * 30, httpOnly: true },
}

export function getCookieSettings (type?: keyof typeof settings): { maxAge: number, httpOnly: boolean } | undefined {
  if (!type) return settings.default

  return settings[type]
}
