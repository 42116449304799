<script setup lang="ts">
import {
  ref,
  computed,
  shallowRef,
  onServerPrefetch,
  onMounted,
  defineAsyncComponent,
  watchEffect,
  h,
  hydrateOnInteraction,
  onUnmounted,
  onBeforeMount,
} from 'vue'
import { addServerState, getServerState, useRoute, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { type Brand, type Market, castBrandAndMarketToSite, getInfoBanners, getNavigationMenu } from '@ocp-zmarta/content-sdk'

import { useZGA } from '@/plugins/zga.ts'
import { setGoogleParamsAsCookies } from '@/utils/index.ts'
import { getCookie, setCookie, setParamsAsCookies } from '@/utils/tracking'
import { logError } from '@/utils/misc/log-error.ts'
import { getUserAttributes } from '@/utils/helper-functions/index.ts'
import type { RouteContext } from '@/utils/helper-functions/get-user-attributes.ts'
import requests from '@/requests/index.ts'
import useScripts from '@/composables/useScriptsHeader'

import { useMiscStore } from '@/store/misc.ts'
import { useAuthenticationStore } from '@/store/authentication.ts'
import { useMessagesStore } from '@/store/messages.ts'
import { useModalStore } from '@/store/modal.ts'
import { useCustomerStore } from '@/store/customer.ts'
import { useLoaderStore } from '@/store/loader.ts'
import { useRouteStore } from '@/store/route.ts'
import { useContentStore } from '@/store/content.ts'
import { useTranslationsStore } from '@/store/translations.ts'
import { useConfig } from '@/store/config.ts'
import { useUserAttributes } from '@/store/user-attributes.ts'

import { emitter } from '@/composables/eventBus.ts'

// Components
import AuthenticationListener from '@/header/components/misc/AuthenticationListener.vue'
import EventListener from '@/header/components/misc/EventListener.vue'
import Translations from '@/header/components/misc/Translations.vue'
import { formatHeaderMenuContent } from '@/format-content.ts'
import { selectInfoBanner } from '@/utils/info-banner'

const Shortcuts = defineAsyncComponent({
  loader: async () => await import('@/header/components/desktop/ShortCuts.vue'),
  hydrate: hydrateOnInteraction('mouseover'),
})

const Dropdown = defineAsyncComponent({
  loader: async () => await import('@/header/components/mobile/Dropdown.vue'),
  hydrate: hydrateOnInteraction('mouseover'),
})

const Hamburger = defineAsyncComponent({
  loader: async () => await import('@/header/components/mobile/Hamburger.vue'),
  hydrate: hydrateOnInteraction('mouseover'),
})

const Login = defineAsyncComponent({
  loader: async () => await import('@/header/components/login/Login.vue'),
  hydrate: hydrateOnInteraction('click'),
})

const MyAccount = defineAsyncComponent(async () => await import('@/header/components/misc/MyAccount.vue'))
const Phone = defineAsyncComponent(async () => await import('@/header/components/misc/Phone.vue'))
const ZgInfoBanner = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgInfoBanner))

// Stores
const miscStore = useMiscStore()
const authenticationStore = useAuthenticationStore()
const messagesStore = useMessagesStore()
const modalStore = useModalStore()
const customerStore = useCustomerStore()
const loaderStore = useLoaderStore()
const routeStore = useRouteStore()
const contentStore = useContentStore()
const clientConfig = useConfig()
const translationsStore = useTranslationsStore()
const userAttributesStore = useUserAttributes()

const ZGA = useZGA()
const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      locales: getServerState('locales'),
      market: getServerState('market'),
      brand: getServerState('brand'),
      isZmarta: getServerState('isZmarta'),
      isElskling: getServerState('isElskling'),
      isBroker: getServerState('isBroker'),
      isSweden: getServerState('isSweden'),
      isNorway: getServerState('isNorway'),
      isFinland: getServerState('isFinland'),
      isMobile: getServerState('isMobile'),
      route: getServerState('route'),
      config: getServerState('config'),
    }

const usedRoute = useRoute()
// Query params
const isReview = ref<boolean>(usedRoute?.searchParams.get('review') === 'true')
const isLocal = ref<boolean>(usedRoute?.searchParams.get('local') === 'true')
const isTestCafe = ref<boolean>(!!usedRoute?.searchParams.get('testcafe'))
const minimalParam = ref<boolean>(!!usedRoute?.searchParams.get('minimal'))
const loaded = ref(false)

// Declare Cookiebot in the Window interface
declare global {
  interface Window {
    dataLayer: any[]
    Cookiebot?: {
      consent?: {
        marketing?: boolean
        necessary?: boolean
        preferences?: boolean
        statistics?: boolean
      }
    }
    getCookieConsent: () => {
      necessary: boolean
      preferences: boolean
      statistics: boolean
      marketing: boolean
    }
  }
}

export interface InfoBanner {
  entityId: string
  warning: boolean
  icon?: string
  text: string
  paths?: string[]
  applyToAllSubPaths: boolean
}

// Add scripts
useScripts({
  isSweden: ctx?.isSweden,
  isFinland: ctx?.isFinland,
  isNorway: ctx?.isNorway,
  isZmarta: ctx?.isZmarta,
  isBroker: ctx?.isBroker,
  isProduction: ctx?.config?.environment !== 'development',
})

const infoBanner = ref<InfoBanner | undefined>()

// Server-side data fetching
onServerPrefetch(async () => {
  const locale = ctx?.route.locale
  const site = castBrandAndMarketToSite(ctx?.brand as Brand, ctx?.market as Market)

  const menuContent = await getNavigationMenu({ site, locale })

  // Info banner
  const infoBanners = await getInfoBanners({ site, locale }) as InfoBanner[]
  addServerState('infoBanners', infoBanners)
  infoBanner.value = selectInfoBanner({ infoBanners: getServerState('infoBanners')!, path: ctx?.route?.pathname })

  const staticContent = locale && ctx?.locales?.options?.includes(locale)
    ? await import(`../header/content/${ctx?.market}/${locale}/index.ts`)
    : await import(`../header/content/${ctx?.market}/${ctx?.locales?.default}/index.ts`)

  const constantsMarket = await import(`../header/constants/${ctx?.market}.ts`)
  const constants = await import('@/header/constants/index.ts')

  const content = {
    ...formatHeaderMenuContent({
      menuContent,
      isElskling: ctx?.isElskling,
      pathname: ctx?.route?.pathname,
    }),
    ...staticContent.default,
    ...constantsMarket.default,
    ...constants.default,
  }

  const userAttributes = getUserAttributes(ctx as RouteContext)
  userAttributesStore.$state.attributes = userAttributes

  translationsStore.setLocale({ locale })
  contentStore.setContent({ content })
  clientConfig.setConfig({ serviceName: ctx?.config?.serviceName })

  if (ctx?.isMobile != null) {
    miscStore.setMisc({
      isMobile: ctx.isMobile,
      isDesktop: !ctx.isMobile,
    })
  }

  routeStore.setRoute({
    market: ctx?.market,
    brand: ctx?.brand,
    isZmarta: ctx?.isZmarta,
    isElskling: ctx?.isElskling,
    isBroker: ctx?.isBroker,
    isSweden: ctx?.isSweden,
    isNorway: ctx?.isNorway,
    isFinland: ctx?.isFinland,
  })

  if (ctx?.locales) {
    translationsStore.$patch({
      locale: ctx?.route.locale,
      default: ctx?.locales.default,
      options: ctx?.locales.options,
    })
  }

  addServerState('isTestCafe', isTestCafe.value)

  void import('@ocp-zmarta/zsc-plugin-vite-ssr/vue').then(({ useServerHead }) => {
    useServerHead({
      htmlAttrs: {
        lang: ctx?.brand,
      },
      ...(isLocal.value || isReview.value
        ? { title: 'zmarta-header' }
        : {}),
      meta: [
        ...(isLocal.value || isReview.value
          ? [
              { charset: 'utf-8' },
              { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            ]
          : []),
      ],
    })
  })
})

let iconPaths: Record<string, any> = {}

if (ctx?.isZmarta) {
  iconPaths = import.meta.glob('/public/images/common/logos/zmarta.svg', { query: 'raw', import: 'default' })
} else if (ctx?.isElskling) {
  iconPaths = import.meta.glob('/public/images/common/logos/elskling.svg', { query: 'raw', import: 'default' })
} else if (ctx?.isBroker) {
  if (ctx?.isSweden) { iconPaths = import.meta.glob('/public/images/se/logos/broker.svg', { query: 'raw', import: 'default' }) }
  if (ctx?.isNorway) { iconPaths = import.meta.glob('/public/images/no/logos/broker.svg', { query: 'raw', import: 'default' }) }
  if (ctx?.isFinland) { iconPaths = import.meta.glob('/public/images/fi/logos/broker.svg', { query: 'raw', import: 'default' }) }
}

const icons = Object.fromEntries(Object.entries(iconPaths).map(([path, url]) => [path?.split('/')?.at(-1)?.replace(/\.\w+$/, ''), url]))
const selectedIcon = shallowRef()

watchEffect(() => {
  const selectedIconTemplate = icons[ctx?.brand]
  if (selectedIconTemplate) {
    selectedIcon.value = defineAsyncComponent({
      loader: async () => {
        const innerHTML = await selectedIconTemplate()
        const component = h('span', { innerHTML })
        return { name: `logo-${ctx?.brand}`, render: () => component }
      },
      hydrate: hydrateOnInteraction('click'),
    })
  } else {
    selectedIcon.value = null
  }
})

const trackPageView = async () => {
  if (ctx?.isBroker || (ctx?.isElskling && ctx?.isNorway)) return

  await ZGA?.event?.meta?.pageView({
    userAgent: userAttributesStore.$state?.attributes?.source,
    userIp: userAttributesStore.$state?.attributes?.userIp,
  })

  if ((window as Window).Cookiebot?.consent?.marketing) {
    await postPageView()
  }
}

const postPageView = async () => {
  try {
    if (!getCookie('zCid')) {
      const uuid = '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
        (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> (parseInt(c) / 4)).toString(16)
      )

      setCookie('zCid', uuid, 30)
    }

    // only set gclid as a cookie after cookiebot consent
    setGoogleParamsAsCookies(window.document.location.href)

    await requests.serviceCommonGateway.tracking.pageView({ url: window.location.href })
  } catch (error: any) {
    logError('trackPageView/postPageView', { error })
  }
}

const initCookieBot = async () => {
  /**
             * The event is triggered if the user accepts the use of cookies. The event is also triggered if the user has consented at an earlier visit to the website.
             * https://www.cookiebot.com/en/developer/#h-event-handling
             */
  window.addEventListener('CookiebotOnAccept', handleTrackPageViewEvent)

  // This is needed as cookiebot is not enabled in test
  if (!window?.Cookiebot) {
    await postPageView()
  }

  setParamsAsCookies(window.document.location.href)

  const maxTimes = 10
  let times = 0

  const interval = setInterval(() => {
    let exit = false

    try {
      const res = ZGA?.inferProduct?.()
      if (res) exit = true
    } catch {
      exit = true
    }

    times++
    if (exit || times > maxTimes) clearInterval(interval)
  }, 300)

  window.getCookieConsent = () => ({
    necessary: window.Cookiebot?.consent?.necessary ?? false,
    preferences: window.Cookiebot?.consent?.preferences ?? false,
    statistics: window.Cookiebot?.consent?.statistics ?? false,
    marketing: window.Cookiebot?.consent?.marketing ?? false,
  })
}

const showMenu = computed(() => miscStore.showMenu)
const showLogin = computed(() => miscStore.showLogin)
const showShortcuts = computed(() => !ctx?.isMobile && !minimalParam.value)
const showHamburger = computed(() => customerStore.getCustomer.loggedIn || !minimalParam.value)

const goToStartPage = async () => {
  await trackNavigationClick('logo')
  emitter.emit('go-to-start-page')
}

const trackNavigationClick = async (label: string) => {
  await ZGA?.event?.navigation?.click?.({
    category: 'main-menu',
    label: label?.replace(/^\//, ''),
  })
}

const toggleMenu = async () => {
  const showMenu = !miscStore.showMenu
  miscStore.setMisc({ menuIsVisible: showMenu })

  const event = showMenu ? 'hamburger-show' : 'hamburger-hide'
  await trackNavigationClick(event)
}

const toggleLogin = async () => {
  if (authenticationStore.getAuthentication.loading) return

  if (!miscStore.showLogin) {
    await messagesStore.resetMessages()
    authenticationStore.resetLoading()
  } else {
    await authenticationStore.cancelEid()
  }

  const showLogin = !miscStore.showLogin
  miscStore.setMisc({ loginIsVisible: showLogin })

  const event = showLogin ? 'login-show' : 'login-hide'
  await trackNavigationClick(event)
}

const init = async () => {
  await Promise.all([
    modalStore.hideModal(),
    loaderStore.hideLoader(),
  ])

  authenticationStore.resetLoading()
}

const handleLoginEvent = () => { void toggleLogin() }
const handleTrackPageViewEvent = () => { void trackPageView() }

onBeforeMount(() => {
  infoBanner.value = selectInfoBanner({ infoBanners: getServerState('infoBanners')!, path: ctx?.route?.pathname ?? window?.location?.pathname })
})

const checkZgStoc = () => {
  const zgStocElement = document.querySelector('.zg-stoc')
  const topMargin = zgStocElement && window.getComputedStyle(zgStocElement).display !== 'none' ? '3.5rem' : '0'
  document.documentElement.style.setProperty('--zg-site-navigation--top-margin', topMargin)
}

onMounted(async () => {
  window.addEventListener('scroll', checkZgStoc)
  checkZgStoc()

  emitter.on('login:show', () => { handleLoginEvent() })
  emitter.on('login:close', () => { handleLoginEvent() })

  await init()
  await initCookieBot()
  await customerStore.fetchCustomer()

  window.addEventListener('zmarta-header:login', handleLoginEvent)
})

onUnmounted(() => {
  window.removeEventListener('scroll', checkZgStoc)
  window.removeEventListener('zmarta-header:login', handleLoginEvent)
  window.removeEventListener('CookiebotOnAccept', handleTrackPageViewEvent)

  emitter.off('login:show')
  emitter.off('login:close')
})
</script>

<template>
  <header
    :class="[
      'zmarta-header',
      `theme--${ctx?.brand.replace('broker', 'zmarta')}`,
      {
        [`theme--${ctx?.brand}-${ctx?.market}`]: ctx?.isBroker,
        'zmarta-header--loaded': loaded,
        [`zmarta-header--${ctx?.market}`]: ctx?.market
      }
    ]"
  >
    <nav>
      <a key="logo" class="logo" href="/" @click.prevent="goToStartPage">
        <component :is="selectedIcon" v-if="selectedIcon" />
      </a>

      <shortcuts v-if="showShortcuts" key="shortcuts" @track-navigation-click="trackNavigationClick" />

      <div key="right" class="right">
        <phone key="phone" :minimal="minimalParam" @track-navigation-click="trackNavigationClick" />

        <my-account
          v-if="!(ctx?.isElskling && ctx?.isNorway)"
          key="my-account"
          :minimal="minimalParam"
          @track-navigation-click="trackNavigationClick"
        />

        <translations
          v-if="translationsStore.showTranslations"
          key="translations"
          :minimal="minimalParam"
          @track-navigation-click="trackNavigationClick"
        />

        <hamburger v-if="showHamburger" key="hamburger" @toggle-menu="toggleMenu" />

        <dropdown v-if="showMenu" key="dropdown" :minimal="minimalParam" @toggle-menu="toggleMenu" />
      </div>
    </nav>

    <zg-info-banner
      v-if="infoBanner?.text"
      :text="infoBanner.text"
      :bgcolor="infoBanner?.warning ? 'secondary-500' : undefined"
      :icon="infoBanner?.warning ? 'warning' : infoBanner?.icon"
    />
    <div>
      <login v-if="showLogin || (ctx?.isElskling && authenticationStore.getAuthentication.loading)" />

      <event-listener />
      <authentication-listener />
    </div>
  </header>
</template>

<style lang="scss" scoped>
  @import '/vue/scss/breakpoints.scss';

  :root {
    --zg-site-navigation--top-margin: 0rem;
  }

  .zmarta-header {
    position: fixed;
    background-color: var(--white);
    top: 0;
    left: 0;
    right: 0;
    height: rem(56px);
    z-index: 50;

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      max-width: rem(1920px);
      margin: 0 auto var(--zg-site-navigation--top-margin) auto;
      padding: 0 rhythm(small);
    }

    :deep(.logo) {
      display: block;
      height: 100%;
      border: none;
      padding: rem(12px) 0;

      @include mq(medium) {
        padding: rem(8px) 0;
      }
    }

    :deep(svg) {
      height: 100%;
      width: auto;
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rhythm(small);
      height: 100%;
      margin-left: auto;
    }
  }

  // ? ----------------------------------------------------------------------
  // modifiers
  // ? ----------------------------------------------------------------------

  .zmarta-header--se {
    nav {
      @include mq($se-breakpoint-desktop + 1) {
        gap: rhythm(small);
        padding: 0 rem(48px);
      }
    }
  }

  .zmarta-header--no {
    nav {
      @include mq($no-breakpoint-desktop + 1) {
        gap: rhythm(small);
        padding: 0 rem(48px);
      }
    }
  }

  .zmarta-header--fi {
    nav {
      @include mq($fi-breakpoint-desktop + 1) {
        gap: rhythm(small);
        padding: 0 rem(48px);
      }
    }
  }

  .theme--broker-se,
  .theme--broker-fi {
    height: rem(80px) !important;
    background-color: #24385b !important;

    &.zmarta-header--se,
    &.zmarta-header--fi {
      .logo {
        height: rem(35px) !important;
        padding: 0 !important;

        @include mq(medium) {
          height: rem(50px) !important;
          padding: 0 !important;
        }

        img {
          width: auto !important;
          height: 100% !important;
        }
      }

      :deep(.overlay-loader) {
        background-color: #24385b !important;
      }

      :deep(.shortcuts) {
        ul {
          justify-content: flex-end !important;

          a {
            @include type-body-xs;

            font-weight: font-weight(bold);
            color: #fff !important;
            margin: 0 !important;

            &.current {
              background-color: transparent !important;
            }
          }

          li:hover {
            a {
              color: #fff !important;
              background-color: transparent !important;
            }
          }
        }

        .right {
          margin-left: unset !important;
        }
      }

      .right {
        .dropdown {
          top: rem(80px);

          .dropdown-sub-menus {
            background-color: #f2f2f2 !important;
          }
        }
      }

      :deep(.phone) {
        svg {
          color: #fff !important;
        }

        .phone-no {
          display: none !important;
        }
      }

      :deep(.my-account) {

        .login-btn,
        .customer-btn {
          color: #24385b !important;
          border-color: #fff !important;
          background-color: #fff !important;

          &:hover {
            background-color: #fff !important;
          }

          svg {
            color: #24385b !important;
          }
        }

        ul {
          background-color: #24385b !important;

          &::before {
            top: - rem(40px);
            background-color: #24385b !important;
          }

          li::after {
            background-color: #fff !important;
          }

          a,
          a:hover {
            color: #fff !important;
          }

          path {
            fill: #fff !important;
          }
        }
      }

      :deep(.hamburger) {
        .zg-icon {
          color: #fff !important;
        }

        span {
          background: var(--white);
        }
      }
    }

    :deep(.login-modal) {
      .zg-button {
        background-color: #1db5e4 !important;
        border-color: #1db5e4 !important;
      }
    }
  }

  .theme--broker-no {
    height: rem(80px) !important;
    background-color: #fff !important;
    border-bottom: 1px solid #f2f2f2 !important;

    &.zmarta-header--no {
      .logo {
        height: rem(35px) !important;
        padding: 0 !important;

        @include mq(medium) {
          height: rem(50px) !important;
          padding: 0 !important;
        }

        img {
          width: auto !important;
          height: 100% !important;
        }
      }

      .overlay-loader {
        background-color: #fff !important;
      }

      :deep(.shortcuts) {
        ul {
          justify-content: flex-end !important;

          a {
            @include type-body-xs;

            font-weight: font-weight(bold);
            color: var(--black) !important;
            margin: 0 !important;

            &.current {
              background-color: transparent !important;
            }
          }

          li:hover {
            a {
              color: var(--black) !important;
              background-color: transparent !important;
            }
          }
        }

        .right {
          margin-left: unset !important;
        }
      }

      .right {
        .dropdown {
          top: rem(80px);

          .dropdown-sub-menus {
            background-color: #f2f2f2 !important;
          }
        }
      }

      :deep(.phone) {
        svg {
          color: #24385b !important;
        }

        .phone-no {
          display: none !important;
        }
      }

      :deep(.my-account) {

        .login-btn,
        .customer-btn {
          color: #24385b !important;
          border-color: #00a7f8 !important;
          background-color: var(--white) !important;

          &:hover {
            background-color: var(--white) !important;
          }

          svg {
            color: #24385b !important;
          }
        }

        ul {
          background-color: #f2f2f2 !important;

          &::before {
            top: - rem(40px);
            background-color: #f2f2f2 !important;
          }

          li::after {
            background-color: #24385b !important;
          }

          path {
            fill: #24385b !important;
          }
        }
      }

      :deep(.hamburger) {
        .zg-icon {
          color: var(--black) !important;
        }
      }
    }

    :deep(.login-modal) {
      .zg-button {
        background-color: #00a7f8 !important;
        border-color: #00a7f8 !important;
      }
    }
  }
</style>
