<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'

import { useAuthenticationStore } from '@/store/authentication'
import { useFormStore } from '@/store/form'
import { useMiscStore } from '@/store/misc'
import { useTranslationsStore } from '@/store/translations'
import { useContentStore } from '@/store/content'
import { emitter } from '@/composables/eventBus'
import { getLogger, useRoute } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { setBankidStartUrl, setFrejaStartUrl } from '@/utils'
import { useMessagesStore } from '@/store/messages'
import { useCustomerStore } from '@/store/customer'
import type { EidProviders } from '@/header/constants/types'
import { ProviderTypes } from '@/header/constants/types'

const authenticationStore = useAuthenticationStore()
const formStore = useFormStore()
const miscStore = useMiscStore()
const transitionStore = useTranslationsStore()
const contentStore = useContentStore()
const messageStore = useMessagesStore()
const customerStore = useCustomerStore()
const route = useRoute()
const minimalParam = route?.searchParams.get('minimal') === 'true'
const secretPoll = ref<ReturnType<typeof setInterval> | null>(null)
const secretPollInterval = 1000
const collectPoll = ref<ReturnType<typeof setInterval> | null>(null)
const collectPollInterval = 2000
const timeout = ref<ReturnType<typeof setTimeout> | null>(null)
const timeoutAge = 180000

const getAuthentication = computed(() => authenticationStore.getAuthentication)
const getIsDesktop = computed(() => miscStore.getIsDesktop)
const getLocale = computed(() => transitionStore.getLocale)
const autostarttoken = computed(() => getAuthentication.value?.autoStartToken)

async function initiateEidLogin (provider: EidProviders) {
  killSecretPoll()
  killCollectPoll()

  formStore.setField({
    field: 'provider',
    value: provider,
  })

  const response = await authenticationStore.initiateEid()
  if (!response) return

  if (contentStore.getContent?.AUTHENTICATION?.POLL_SECRET && getIsDesktop.value) {
    await authenticationStore.secretEid()

    if (provider === contentStore.getContent?.EID_PROVIDERS.BANKID) {
      await pollSecret()
    }
  }

  if (contentStore.getContent?.AUTHENTICATION.POLL_EID) {
    if (provider === contentStore.getContent?.EID_PROVIDERS.BANKID && !getIsDesktop.value) {
      openBankId()
    }

    if (provider === contentStore.getContent?.EID_PROVIDERS.FREJA && !getIsDesktop.value) {
      openFreja()
    }

    await pollCollect()
  }

  if (contentStore.getContent?.AUTHENTICATION.REDIRECT_EID) {
    if (!response || contentStore.getContent?.TESTCAFE) return

    setTimeout(() => {
      window.location.href = response as unknown as string
    }, 300)
  }
}

async function initiatePasswordLogin () {
  killSecretPoll()
  killCollectPoll()

  const initiated = await authenticationStore.initiatePassword()
  const authCreated = initiated ? await authenticationStore.createOauth('password') : null
  const locale = getLocale.value!
  const location = contentStore.getContent?.MY_ACCOUNT_PATH_PASSWORD?.[locale]

  if (authCreated && location) window.location.href = location
}

function openBankId () {
  if (autostarttoken.value) {
    window.location.href = setBankidStartUrl(autostarttoken.value)
  }
}

function openFreja () {
  if (autostarttoken.value) window.location.href = setFrejaStartUrl(autostarttoken.value)
}

async function pollSecret () {
  killSecretPoll()

  secretPoll.value = setInterval(() => {
    void (async () => {
      const complete = await authenticationStore.secretEid()
      if (!complete && !authenticationStore.getAuthentication.token) {
        killSecretPoll()
      }
    })()
  }, secretPollInterval)

  setTimeoutHandler()
}

async function pollCollect () {
  killCollectPoll()

  collectPoll.value = setInterval(() => {
    void (async () => {
      const complete = await authenticationStore.collectEid()

      if (complete) {
        killCollectPoll()
        await createAuth()
      } else if (!complete && !getAuthentication.value.token) {
        killCollectPoll()
      }
    })()
  }, collectPollInterval)

  setTimeoutHandler()
}

async function verifyEid () {
  const resolved = await authenticationStore.collectEid()
  if (resolved) await createAuth()
}

async function createAuth (type?: string) {
  const resolved = await authenticationStore.createOauth(type)
  if (!resolved) return

  const locale = getLocale.value!
  const myAccountPath = contentStore.getContent?.MY_ACCOUNT_PATH
  const location = myAccountPath?.[locale as keyof typeof myAccountPath]

  if ((location && window.location?.pathname?.includes(location)) || minimalParam) {
    window.location.reload()
    return
  }

  let url = myAccountPath?.[locale as keyof typeof myAccountPath]
  if (contentStore.getContent?.BROKER) url = contentStore.getContent?.MY_ACCOUNT_PATH_PASSWORD?.[locale]

  if (url) window.location.href = url
}

function setTimeoutHandler () {
  timeout.value = setTimeout(() => {
    void (async () => {
      await messageStore.setModalMessage({
        group: 'authentication',
        name: 'expiredTransaction',
      })
    })()

    if (!secretPoll.value || !collectPoll.value) return

    killSecretPoll()
    killCollectPoll()
  }, timeoutAge)
}

async function loggedOut () {
  killSecretPoll()
  killCollectPoll()

  await authenticationStore.logout()
  await messageStore.resetMessages()
  customerStore.$hydrate({ runHooks: false })

  emitter.emit('login:show')
}

function killSecretPoll () {
  clearInterval(secretPoll.value!)
  secretPoll.value = null

  clearTimeout(timeout.value!)
  timeout.value = null
}

function killCollectPoll () {
  clearInterval(collectPoll.value!)
  collectPoll.value = null

  clearTimeout(timeout.value!)
  timeout.value = null
}

async function cancel () {
  killSecretPoll()
  killCollectPoll()

  await authenticationStore.cancelEid()
}

function redirectOnLogout () {
  emitter.emit('go-to-start-page')
}

const handleLogout = async () => { await authenticationStore.logout() }
const handleAutoLoggedOut = async () => { await authenticationStore.autoLogout() }
const handleLoggedOut = async () => { await loggedOut() }
const handleFetchCustomer = async () => await customerStore.fetchCustomer()

const handleLogoutEvent = () => void handleLogout()
const handleAutoLoggedOutEvent = () => void handleAutoLoggedOut()
const handleLoggedOutEvent = () => void handleLoggedOut()
const handleFetchCustomerEvent = () => void handleFetchCustomer()

onMounted(async () => {
  if (contentStore.getContent?.AUTHENTICATION?.VERIFY_EID) {
    await verifyEid()
  }
  emitter.on('authentication:initiate', ({ loginType, provider }: { loginType: ProviderTypes, provider: EidProviders }) => {
    if (loginType === ProviderTypes.PASSWORD) {
      initiatePasswordLogin().catch(e => { getLogger().error(e) })
    } else if (loginType === ProviderTypes.EID) {
      initiateEidLogin(provider).catch(e => { getLogger().error(e) })
    }
  })

  emitter.on('authentication:cancel', () => {
    void (async () => {
      await cancel()
    })()
  })

  emitter.on('authentication:kill:secret', killSecretPoll)
  emitter.on('authentication:kill:collect', killCollectPoll)
  emitter.on('authentication:bankid:open', openBankId)
  emitter.on('authentication:freja:open', openFreja)
  emitter.on('logout:redirect', redirectOnLogout)

  window.addEventListener('zmarta-header:logout', handleLogoutEvent)
  window.addEventListener('zmarta-header:logout:not-authorized', handleAutoLoggedOutEvent)
  window.addEventListener('zmarta-header:logout:login', handleLoggedOutEvent)
  window.addEventListener('zmarta-header:get-customer', handleFetchCustomerEvent)
})

onUnmounted(() => {
  emitter.off('authentication:initiate')
  emitter.off('authentication:cancel')
  emitter.off('authentication:kill:secret')
  emitter.off('authentication:kill:collect')
  emitter.off('authentication:bankid:open')
  emitter.off('authentication:freja:open')
  emitter.off('logout:redirect')

  window.removeEventListener('zmarta-header:logout', handleLogoutEvent)
  window.removeEventListener('zmarta-header:logout:not-authorized', handleAutoLoggedOutEvent)
  window.removeEventListener('zmarta-header:logout:login', handleLoggedOutEvent)
  window.removeEventListener('zmarta-header:get-customer', handleFetchCustomerEvent)
})
</script>

<template>
  <div />
</template>
