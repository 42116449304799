import { defineStore } from 'pinia'
import { useRoute } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { setCookie } from '@/utils/tracking/set-cookie'
import { useZmartaSessionStore } from './zmartaSession'
import { useContentStore } from './content'
import { useRouteStore } from './route'
import type { Locale } from '@ocp-zmarta/content-sdk'
import { emitter } from '@/composables/eventBus'

interface State {
  locale?: Locale
  default?: Locale
  options: Locale[]
}

export const useTranslationsStore = defineStore('translations', {
  state: (): State => ({
    locale: undefined,
    default: undefined,
    options: [],
  }),

  getters: {
    getLocale: (state): Locale | undefined => state.locale,
    getDefaultLocale: (state): Locale | undefined => state.default,
    getIsDefaultLocale: (state): boolean => state.default === state.locale,
    getLocales (state): Array<Record<string, any>> {
      const contentStore = useContentStore()

      return state.options.map(item => ({
        ...contentStore.getContent?.elements?.header?.languages?.[item],
        value: item,
      }))
    },
    showTranslations (state): boolean {
      const route = useRoute()
      const routeStore = useRouteStore()

      return (
        !!route?.searchParams.get('show_translations') &&
        this.getLocales.length > 1 &&
        routeStore.getRoute.isZmarta
      )
    },
  },

  // Actions
  actions: {
    setLocale ({ locale }: { locale: Locale }) {
      if (!locale || !this.options.includes(locale)) return

      setCookie('locale', locale)

      const zmartaSession = useZmartaSessionStore()
      const slugs = zmartaSession.getSession?.slugs
      const slug = slugs?.[locale]
      // metric('translation', `changed_to_${locale}`)

      if (slug) {
        window.location.href = slug
      }

      emitter.emit('go-to-start-page', locale)
    },
  },
  persist: !import.meta.env.SSR
    ? {
        storage: sessionStorage,
      }
    : undefined,
})
