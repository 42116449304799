function getUserAgent (): string {
  return navigator.userAgent || (window as any).opera || ''
}

function getMobileOperatingSystem (): 'windowsPhone' | 'android' | 'ios' | 'unknown' {
  const userAgent = getUserAgent()

  if (/windows phone/i.test(userAgent)) {
    return 'windowsPhone'
  } else if (/android/i.test(userAgent)) {
    return 'android'
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'ios'
  }

  return 'unknown'
}

function setRedirect (): string | null {
  const mobileOS = getMobileOperatingSystem()
  let redirect: string | null = null

  if (mobileOS === 'windowsPhone') {
    redirect = encodeURIComponent(window.location.href)
  } else if (mobileOS === 'ios') {
    const userAgent = navigator.userAgent

    if (/CriOS/.exec(userAgent)) {
      redirect = encodeURIComponent('googlechromes://')
    } else if (/FxiOS/.exec(userAgent)) {
      redirect = encodeURIComponent(`firefox://open-url?url=${window.location.href}`)
    } else if (/GSA/.exec(userAgent)) {
      redirect = encodeURIComponent(`googleapp://open-url?url=${window.location.href}`)
    } else if (/EdgiOS/.exec(userAgent)) {
      redirect = encodeURIComponent('microsoft-edge-https://')
    } else if (/FBAN/.exec(userAgent)) {
      redirect = encodeURIComponent('fb://')
    } else {
      redirect = encodeURIComponent(`${window.location.href}#${randomString(4)}`)
    }
  }

  return redirect
}

function setHost (): string {
  const mobileOS = getMobileOperatingSystem()
  const userAgent = getUserAgent()

  if (
    (mobileOS === 'ios' && userAgent.includes('GSA')) ||
    (mobileOS === 'ios' && userAgent.includes('FBAN'))
  ) {
    return 'https://app.bankid.com/'
  } else {
    return 'bankid:///'
  }
}

/**
 * @param {string|null} autostarttoken
 * @returns {string}
 */
export function setBankidStartUrl (autostarttoken: string): string {
  return `${setHost()}?autostarttoken=${autostarttoken}&redirect=${setRedirect()}`
}

// Utility function that was referenced but not defined in the original code
function randomString (length: number): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}
