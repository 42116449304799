// useLoaderStore.ts
import { defineStore } from 'pinia'
import { useModalStore } from './modal'

export interface LoaderState {
  active: boolean
  type: string | null
}

export const useLoaderStore = defineStore('loader', {
  state: (): LoaderState => ({
    active: false,
    type: null,
  }),

  getters: {
    getLoader: (state) => state,
  },

  actions: {
    async showLoader () {
      const modalStore = useModalStore()
      await modalStore.hideModal()
      this.mutateLoader({ active: true, type: null })
      return true
    },

    async hideLoader () {
      this.$reset()
      return true
    },

    mutateLoader (payload: { active: boolean, type?: string | null }) {
      this.active = payload.active
      this.type = payload.type ?? null
    },
  },
  persist: !import.meta.env.SSR
    ? {
        storage: sessionStorage,
      }
    : undefined,
})
