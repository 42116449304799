// useModalStore.ts
import { defineStore } from 'pinia'
import { useLoaderStore } from './loader'

// types.ts
export interface ModalState {
  active: boolean
}

export const useModalStore = defineStore('modal', {
  state: (): ModalState => ({
    active: false,
  }),

  getters: {
    getModal: (state) => state,
  },

  actions: {
    async showModal () {
      if (typeof window === 'undefined') return
      if (JSON.stringify(window.history.state) === JSON.stringify({ name: 'modal' })) return

      window.history.pushState({ name: 'modal' }, '', null)

      const loaderStore = useLoaderStore()
      await loaderStore.hideLoader()
      this.mutateModal(true)
      return true
    },

    async hideModal () {
      this.mutateModal(false)
      return true
    },

    mutateModal (active: boolean) {
      this.active = active
    },
  },
})
