import { defineStore } from 'pinia'

interface State {
  attributes?: {
    source?: string
    userIp?: string
  }
}

export const useUserAttributes = defineStore('userAttributes', {
  state: (): State => ({
    attributes: undefined,
  }),
})
