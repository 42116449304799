<script lang="ts" setup>
import { computed, onMounted, ref, defineAsyncComponent } from 'vue'
import { useTranslationsStore } from '@/store/translations'
import type { Locale } from '@ocp-zmarta/content-sdk'
import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
    }

const ZgIcon = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgIcon))
const ZgImage = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgImage))

const props = defineProps<{
  minimal?: boolean
}>()

const minimal = props.minimal ?? false

const translationsStore = useTranslationsStore()

const modelValue = ref<Locale>()

const imgUrl = computed(() => {
  return translationsStore.getLocale ? `${import.meta.env.BASE_URL}/images/${ctx?.market}/translations/${translationsStore.getLocale}.svg` : ''
})

onMounted(() => {
  modelValue.value = translationsStore.getLocale
})

const selectLanguage = (e: Event) => {
  const target = e.target as HTMLSelectElement
  const locale = target.value as Locale

  emit('track-navigation-click', `language/${locale}`)
  translationsStore.setLocale({ locale })
}

const emit = defineEmits<(event: 'track-navigation-click', payload: string) => void>()
</script>

<template>
  <div
    :class="['translations', {
      'translations--minimal': minimal,
      [`translations--${ctx?.market}`]: ctx?.market
    }]"
  >
    <zg-image v-if="imgUrl" :src="imgUrl" :lazy-load="true" />

    <select
      v-model="modelValue"
      name="translation"
      @input="selectLanguage"
      @click="$emit('track-navigation-click', 'show-language')"
    >
      <option
        v-for="(item, index) in translationsStore.getLocales"
        :key="index"
        :value="item.value"
        :selected="item.value === translationsStore.getLocale"
      >
        {{ item.label }}
      </option>
    </select>

    <zg-icon icon="chevron" size="small" />
  </div>
</template>

<style lang="scss" scoped>
  @import '/vue/scss/breakpoints.scss';

  .translations {
    position: relative;
    display: flex;
    align-items: center;
    height: rem(24px);

    picture {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      transform: translateY(-50%);
    }

    select {
      @include type-title-xs;
      position: relative;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      margin: 0;
      cursor: pointer;
      z-index: 1;
      appearance: none;
      padding: 0 rem(20px) 0 rem(28px);
    }

    .zg-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  // ? ----------------------------------------------------------------------
  // modifiers
  // ? ----------------------------------------------------------------------

  .translations--se {
    @include mq($until: 1320px) {
      select {
        width: rem(48px);
      }
    }

    &:not(.translations--minimal) {
      @include mq($until: $se-breakpoint-desktop) {
        display: none;
      }
    }
  }

  .translations--no {
    @include mq($until: 1310px) {
      select {
        width: rem(48px);
      }
    }

    &:not(.translations--minimal) {
      @include mq($until: $se-breakpoint-desktop) {
        display: none;
      }
    }
  }

  .translations--fi {
    @include mq($until: 1310px) {
      select {
        width: rem(48px);
      }
    }

    &:not(.translations--minimal) {
      @include mq($until: $se-breakpoint-desktop) {
        display: none;
      }
    }
  }
</style>
