// useRouteStore.ts
import { defineStore } from 'pinia'

export const useRouteStore = defineStore('route', {
  state: (): any => ({
    market: undefined,
    brand: undefined,
    isZmarta: undefined,
    isElskling: undefined,
    isBroker: undefined,
    isSweden: undefined,
    isNorway: undefined,
    isFinland: undefined,
  }),

  getters: {
    getRoute: (state) => state,
  },

  actions: {
    setRoute (payload: any) {
      if ('market' in payload) {
        this.market = payload.market
      }
      if ('brand' in payload) {
        this.brand = payload.brand
      }
      if ('isZmarta' in payload) {
        this.isZmarta = payload.isZmarta
      }
      if ('isElskling' in payload) {
        this.isElskling = payload.isElskling
      }
      if ('isBroker' in payload) {
        this.isBroker = payload.isBroker
      }
      if ('isSweden' in payload) {
        this.isSweden = payload.isSweden
      }
      if ('isNorway' in payload) {
        this.isNorway = payload.isNorway
      }
      if ('isFinland' in payload) {
        this.isFinland = payload.isFinland
      }
    },
  },
})
