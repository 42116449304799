import type { Market } from '@ocp-zmarta/content-sdk'
import { serviceAuthHeaders } from '@/utils'
import { request } from '@/requests/request'

export interface Initiate {
  market: Market
  ssn?: string
  phone?: string
  signMessage?: string
  eidProvider?: string
}

/**
 * @param {string} market
 * @param {string} ssn
 * @param {string|undefined} phone
 * @param {string|undefined} signMessage
 * @param {number|undefined} eidProvider
 * @returns {Promise<any>}
 */
export async function initiate ({ market, ssn, phone, signMessage, eidProvider }: Initiate): Promise<any> {
  return await request({
    method: 'POST',
    url: '/service/auth/api/v1/eid/initiate',
    headers: {
      ...serviceAuthHeaders(market),
      ...eidProvider ? { 'x-auth-eid-provider': eidProvider } : {},
    },
    body: {
      fetchDoesntLikeAnEmptyBodyForSomeReason: true,
      pno: ssn,
      phone,
      signMessage,
    },
  })
}
