import { isServer } from './is-server'

export function isLoggedIn () {
  const accessExpiresAtCookie = document.cookie
    ?.split('; ')
    .find((row) => row.startsWith('access_expires_at='))
    ?.split('=')[1]

  if (accessExpiresAtCookie) {
    const expirationTimestamp = parseInt(accessExpiresAtCookie)

    if (isNaN(expirationTimestamp)) {
      return false
    }

    const now = Date.now()

    return !isServer() && now < expirationTimestamp
  }

  return false
}
