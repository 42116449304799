export default {
  GA: {
    PRODUCT_FAMILY: 'uncategorized',
    PRODUCT: 'uncategorized',
  },
  RESET_KEYWORD: 'resetUTM',
  UTM_PARAMS: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ],
  GOOGLE_PARAMS: [
    'gclid',
  ],
  MUTABLE_COOKIE_PARAMS: [
    'cross_sell_reference',
  ],
  EID_PROVIDERS: {
    BANKID: 'bankid',
    FREJA: 'freja',
    SIGNICAT: 'signicat',
  },
}
