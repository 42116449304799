import { defineStore } from 'pinia'

interface Config {
  serviceName: string
}

export const useConfig = defineStore('config', {
  state: (): Config => ({
    serviceName: '',
  }),

  getters: {
    getConfig: (state) => state,
  },

  actions: {
    setConfig (payload: Partial<Config>) {
      this.serviceName = payload.serviceName ?? ''
    },
  },
})
