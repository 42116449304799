import type { Market } from '@ocp-zmarta/content-sdk'

/**
 * @param {string} market
 * @returns {{'x-auth-vertical': string, 'x-auth-market', 'x-auth-role': string}}
 */
export function serviceAuthHeaders (market: Market): Record<string, any> {
  return {
    'x-auth-market': market,
    'x-auth-vertical': 'zmarta_menu',
    'x-auth-role': 'customer',
    'x-auth-bankid-version': 6,
  }
}
