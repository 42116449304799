import type { Market } from '@ocp-zmarta/content-sdk'
import { serviceAuthHeaders } from '@/utils'
import { request } from '@/requests/request'

/**
 * @param {string} market
 * @returns {Promise<any>}
 */
export async function refresh ({ market }: { market: Market }): Promise<any> {
  return await request({
    method: 'GET',
    url: '/service/auth/api/v1/oauth/refresh',
    headers: serviceAuthHeaders(market),
    credentials: 'include',
  })
}
