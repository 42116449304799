/**
 * Get the value of a cookie by its name.
 * @param name - The name of the cookie to retrieve.
 * @returns The value of the cookie, or undefined if the cookie doesn't exist.
 */
export function getCookie (name: string): string | undefined {
  if (typeof document === 'undefined' || !name) return undefined

  const value: string = document?.cookie ?? ''
  const cookies: string[] = value?.split(';') ?? []
  const parsed: Record<string, string> = {}

  cookies?.forEach(cookie => {
    const cookieCrumbles = cookie.split('=')

    if (cookieCrumbles.length === 2) {
      const key = cookieCrumbles[0].trim()
      const val = cookieCrumbles[1].trim()

      parsed[key] = parsed[key] || val
    }
  })

  return parsed[name]
}
