import type { InfoBanner } from '@/pages/header.vue'

interface Params {
  infoBanners: InfoBanner[]
  path: string
}

/**
 * - First, checks for banners without paths. If found, returns the first such banner.
 * - If none are found, searches for banners where `applyToAllSubPaths` is `true`
 *   and the given `path` starts with one of the paths in the `paths` array. Returns the first such banner.
 * - If no matches are found, searches for banners with an exact match of the given `path` in the `paths` array.
 * - Returns `undefined` if no matches are found.
 *
 * @param params - The parameters for the function.
 * @param params.infoBanners - The list of banners to choose from.
 * @param params.path - The path to match against the banners.
 * @returns The selected info banner, or `undefined` if no matching banner is found.
 */
export function selectInfoBanner ({ infoBanners, path }: Params) {
  if (!Array.isArray(infoBanners)) return undefined

  const itemsWithoutPaths = infoBanners.filter(item => !item.paths || item.paths.length === 0)

  if (itemsWithoutPaths.length > 0) {
    return itemsWithoutPaths[0]
  }

  const itemsWithSubPath = infoBanners.filter(item => {
    if (!item.paths) return false
    return item.applyToAllSubPaths && item.paths.some((p: string) => path.startsWith(p))
  })

  if (itemsWithSubPath.length > 0) {
    return itemsWithSubPath[0]
  }

  const exactMatchItems = infoBanners.filter(item => item.paths?.includes(path))

  if (exactMatchItems.length > 0) {
    return exactMatchItems[0]
  }

  return undefined
}
