// useMessagesStore.ts
import { defineStore } from 'pinia'
import { useModalStore } from './modal'
import { useContentStore } from './content'

// types.ts
export interface MessagesState {
  successMessage?: string
  statusMessage?: string
  modalMessage?: { text: string }
}

export interface MessagePayload {
  group: string
  name?: Array<{ name: string }> | string
  code?: string
  show?: boolean
}

export const useMessagesStore = defineStore('messages', {
  state: (): MessagesState => ({
    successMessage: undefined,
    statusMessage: undefined,
    modalMessage: undefined,
  }),

  getters: {
    getSuccessMessage: (state) => state.successMessage,
    getStatusMessage: (state) => state.statusMessage,
    getModalMessage: (state) => state.modalMessage,
  },

  actions: {
    async setSuccessMessage ({ group, name }: Omit<MessagePayload, 'show' | 'code'>) {
      if (!group || !name || !(typeof name === 'string')) return

      const contentStore = useContentStore()
      const successMessages = contentStore.getContent?.successMessages
      const statusMessageGroup = successMessages?.[group as keyof typeof successMessages]
      const successMessage = statusMessageGroup?.[name as keyof typeof statusMessageGroup] || successMessages?.[group as keyof typeof successMessages].default

      this.mutateMessages({ successMessage })

      return true
    },

    async setStatusMessage ({ group, name }: Omit<MessagePayload, 'show' | 'code'>) {
      if (!group || !name || !(typeof name === 'string')) return

      const contentStore = useContentStore()
      const statusMessages = contentStore.getContent?.statusMessages
      const statusMessageGroup = statusMessages?.[group as keyof typeof statusMessages]
      const statusMessage = statusMessageGroup?.[name as keyof typeof statusMessageGroup] || statusMessages?.[group as keyof typeof statusMessages].default

      this.mutateMessages({ statusMessage })

      return true
    },

    async setModalMessage ({ group, name, code, show }: MessagePayload) {
      const contentStore = useContentStore()

      const errors = Array.isArray(name) ? name?.[0]?.name : name!
      const modalMessages = contentStore.getContent?.modalMessages

      const modalMessageGroup = modalMessages?.[group as keyof typeof modalMessages]
      let modalMessage = modalMessageGroup?.[errors as keyof typeof modalMessageGroup] || modalMessages?.[group as keyof typeof modalMessages].default

      const errorCodeMessage = code
        ? `<span class="code">${contentStore.getContent?.common?.errorCode}: ${code}</span>`
        : ''

      modalMessage = {
        ...modalMessage,
        text: modalMessage?.text?.replace('{code}', errorCodeMessage) || '',
      }

      this.mutateMessages({ modalMessage })
      if (show) {
        const modalStore = useModalStore()
        await modalStore.showModal()
      }

      return true
    },

    async resetMessages () {
      this.$reset()
    },

    mutateMessages (payload: Partial<MessagesState>) {
      this.successMessage = payload.successMessage
      this.statusMessage = payload.statusMessage
      this.modalMessage = payload.modalMessage
    },
  },
  persist: !import.meta.env.SSR
    ? {
        storage: sessionStorage,
      }
    : undefined,
})
