export enum EidProviders {
  BANKID = 'bankid',
  FREJA = 'freja',
  SIGNICAT = 'signicat',
}

export enum ProviderTypes {
  EID = 'eid',
  PASSWORD = 'password',
}
