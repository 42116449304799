import { acceptHMRUpdate, defineStore } from 'pinia'
import type { EidProviders } from '@/header/constants/types'
import type { FormattedHeaderMenuContent } from '@/format-content'
import type { Locale } from '@ocp-zmarta/content-sdk'

interface Language {
  label: string
}

type Languages = Record<Locale, Language>

export interface LoginForm {
  ssn: {
    label: string
    error: string
  }
  password: {
    label: string
    error: string
  }
}

interface ContentLogin {
  title: string
  subTitle: {
    eid: string
    password: string
  }
  buttons: {
    loginWithBankid: string
    loginWithFreja: string
    openBankid: string
    openFreja: string
    loginWithPassword: string
    cancelEid: string
  }
  links: {
    loginWithPassword: {
      text: string
      label: string
    }
    loginWithEid: string
  }
  messages: {
    eidIsDown: string
  }
  [EidProviders.BANKID]: { steps: string[], sameDevice: { title: string, link: string } }
  [EidProviders.FREJA]: { steps: string[], sameDevice?: { title: string, link: string } }
  [EidProviders.SIGNICAT]: { steps: string[], sameDevice?: { title: string, link: string } }
}

interface Content extends FormattedHeaderMenuContent {
  elements: {
    header?: {
      labels: {
        callUs: string
        login: string
        logout: string
        moreInformation: string
        myAccount: string
      }
      languages: Languages
    }
    login?: ContentLogin
  }
  form: LoginForm
  common: { errorCode: string }
  EID_PROVIDERS: typeof EidProviders
  AUTHENTICATION: {
    POLL_SECRET: boolean
    POLL_EID: boolean
    REDIRECT_EID: boolean
    VERIFY_EID: boolean
  }
  TESTCAFE: boolean
  MY_ACCOUNT_PATH_PASSWORD: Record<Locale, string>
  MY_ACCOUNT_PATH: Record<string, string>
  BROKER: boolean
  statusMessages: {
    customer: {
      saving: string
      default?: string
    }
    authentication: { default: string }
  }
  successMessages: {
    customer: {
      saved: string
      default?: string
    }
    authentication: {
      cancelled: string
      loggedOut: string
      default?: string
    }
  }
  modalMessages: {
    authentication: {
      default: { text: string }
      userForceCancel: { text: string }
      noCustomer: { text: string }
    }
    bankid: { default: { text: string } }
    freja: {
      default: { text: string }
      userCancel: { text: string }
      rpCancel: { text: string }
      expired: { text: string }
      rejected: { text: string }
    }
    customer: { default: { text: string } }
    api: { default: { text: string }, UNSPECIFIED_ERROR: { text: string }, NOT_AUTHORIZED: { text: string } }
  }
}

export interface ContentState {
  content?: Content
}

export const useContentStore = defineStore('content', {
  state: (): ContentState => ({
    content: undefined,
  }),

  getters: {
    getContent: (state): Content | undefined => state.content,
  },

  actions: {
    setContent (payload: { content?: Content }) {
      if (payload.content) {
        this.content = payload.content
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useContentStore, import.meta.hot))
}
