function setRedirect () {
  return window.location.href
}

/**
 * @param {string} autostarttoken
 * @returns {string}
 */
export function setFrejaStartUrl (autostarttoken: string): string {
  return `frejaeid://bindUserToTransaction?transactionReference=${autostarttoken}&originAppScheme=${setRedirect()}`
}
