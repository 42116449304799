import type { Market } from '@ocp-zmarta/content-sdk'
import { serviceAuthHeaders } from '@/utils'
import { request } from '@/requests/request'

/**
 * @param {string} market
 * @param {string} ssn
 * @param {string|undefined} phone
 * @param {string|undefined} password
 * @returns {Promise<any>}
 */
export async function initiate ({ market, ssn, password }: { market: Market.Se | Market.Fi | Market.No, ssn: string, password: string }): Promise<any> {
  return await request({
    method: 'POST',
    url: '/service/auth/api/v1/password/initiate',
    headers: serviceAuthHeaders(market),
    body: {
      pno: ssn,
      password,
    },
  })
}
