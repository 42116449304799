import { defineStore } from 'pinia'

// Define the shape of the state
interface State {
  slugs: Record<string, any>
}

export const useZmartaSessionStore = defineStore('zmartaSession', {
  state: (): State => ({
    slugs: {},
  }),

  getters: {
    getSession: (state): State => state,
  },
})
