import { UnexpectedError } from '@ocp-zmarta/zmarta-errors'

interface RequestOptions {
  method?: string
  url: string
  headers?: Record<string, string>
  body?: object | undefined
  json?: boolean
  credentials?: RequestCredentials
}

/**
 * Safely parse a JSON response. If parsing fails, return the original response.
 * @param response - The response to parse as JSON.
 * @returns Parsed JSON object or the original response if parsing fails.
 */
async function safeParseJson (response: string | object | any[]): Promise<any> {
  try {
    if (typeof response === 'object') {
      return response
    }

    // Parse the response as JSON
    return JSON.parse(response)
  } catch (e) {
    return JSON.stringify(response)
  }
}

/**
 * Perform an HTTP request.
 * @param options - Request options including method, URL, headers, body, etc.
 * @returns Promise resolving to the response data.
 */
async function request (options: RequestOptions): Promise<any> {
  const { method = 'GET', url, headers = {}, body, json = true, credentials = 'omit' } = options

  if (!url) return

  try {
    if (!('Content-Type' in headers)) {
      headers['Content-Type'] = 'application/json'
    }

    let response = await fetch(url, {
      method,
      headers,
      credentials,
      body: body && JSON.stringify(body),
    })

    const success = !!response?.ok
    response = json ? await response?.json() : await response?.text()

    if (!success) response = await safeParseJson(response)

    return response
  } catch (error) {
    throw new UnexpectedError('Oops! Something went wrong!', {
      privateCtx: {
        name: 'REQUEST_ERROR',
        message: 'could not complete request',
        location: 'utils/request',
        url,
        error,
      },
    })
  }
}

export { request }
