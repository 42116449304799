import { getCookie } from './get-cookie'

/**
 * Delete a cookie by name.
 * @param name - The name of the cookie to delete.
 */
export function deleteCookie (name: string): void {
  if (typeof document === 'undefined' || !name) return
  if (!getCookie(name)) return

  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
