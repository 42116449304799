import { isAuthError } from '@/helpers/errors'
import requests from '@/requests'

interface GraphQLError {
  name: string
}

interface ErrorObject {
  graphQLErrors?: GraphQLError[]
  [key: string]: any
}

type AdditionalData = Record<string, any>

export function logError (name: string, error: ErrorObject | any[] | string, additionalData: AdditionalData = {}): void {
  if (isAuthError(error)) return

  function replaceErrors (key: string, value: any): any {
    if (!(value instanceof Error)) return value

    const error: { message: string, name: string, stack?: string } = {
      message: value.message,
      name: value.name,
    }

    if (value.stack) {
      error.stack = value.stack
    }

    return error
  }

  let graphQLError: string | undefined
  if (typeof error === 'object' && !Array.isArray(error) && 'graphQLErrors' in error) {
    graphQLError = (error).graphQLErrors?.[0]?.name
  }

  if (graphQLError) additionalData.graphQLError = graphQLError

  const data = {
    error: JSON.stringify(error, replaceErrors),
    ...additionalData,
  }

  requests.internal
    .log('error', name, data)
    .then(() => true)
    .catch(() => false)
}
