export interface RouteContext {
  userId?: string
  route?: {
    userId?: string
    userIp?: string
    userAgent?: string
    path?: string
    userAttributes?: any
  }
  userIp?: string
  userAgent?: string
  path?: string
}

interface UserAttributes {
  userId?: string
  userIp?: string
  url?: string
  browserAndVersion?: string
  browser?: string
  version?: string | number
}

interface ParsedUserAgent extends UserAttributes {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

export function getUserAttributes (context: RouteContext): UserAttributes {
  const userId = context?.userId || context?.route?.userId
  const userIp = context?.userIp || context?.route?.userIp
  const userAgent = context?.userAgent || context?.route?.userAgent

  const userAttributes: ParsedUserAgent = userAgent ? context?.route?.userAttributes : {} as ParsedUserAgent
  const browserVersion = parseInt(userAttributes.version as string) || userAttributes.version

  userAttributes.url = context?.path || context?.route?.path
  userAttributes.browserAndVersion = `${userAttributes.browser}-${browserVersion}`

  return {
    ...userAttributes,
    userId,
    userIp,
  }
}
