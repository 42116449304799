import { acceptHMRUpdate, defineStore } from 'pinia'
import { useMessagesStore } from './messages'
import { useCustomerStore } from './customer'
import { useLoaderStore } from './loader'
import requests from '@/requests'
import { useMiscStore } from './misc'
import { useFormStore } from './form'
import { useContentStore } from './content'
import { useRouteStore } from './route'
import { emitter } from '@/composables/eventBus'
import { Market } from '@ocp-zmarta/content-sdk'
import { isLoggedIn } from '@/utils/helper-functions'
import { metrics } from '@/utils/metrics/client-metrics'
import { isAuthError } from '@/helpers/errors'
import { useZGA } from '@/plugins/zga'
import type { ProviderTypes } from '@/header/constants/types'
import { logError } from '@/utils/misc/log-error'
import type { EidProviders } from '@/header/constants/types'

const ZGA = useZGA()
interface AuthenticationState {
  success: boolean
  token: string | null
  autoStartToken: string | null
  showSecret: boolean
  secret: string | null
  status: string | null
  hintCode: string | null
  loading: boolean
  verifyEid: boolean
  userCancel: boolean
}

export const useAuthenticationStore = defineStore('authentication', {
  state: (): AuthenticationState => ({
    success: false,
    token: null,
    autoStartToken: null,
    showSecret: false,
    secret: null,
    status: null,
    hintCode: null,
    loading: false,
    verifyEid: false,
    userCancel: false,
  }),
  getters: {
    getAuthentication: (state) => state,
    getAuthenticationIsPendingStart (state) {
      return state.status === 'start' || (state.status === 'pending' && state.hintCode === 'outstandingTransaction')
    },
  },
  actions: {
    setAuthentication (payload: Partial<AuthenticationState>) {
      this.mutateAuth(payload)
    },
    async initiateEid () {
      const messagesStore = useMessagesStore()
      const customerStore = useCustomerStore()
      const routeStore = useRouteStore()

      this.resetAuthentication()
      await messagesStore.resetMessages()
      customerStore.resetCustomer()

      this.mutateAuth({ status: 'start', hintCode: 'start' })
      await requests.serviceAuth.oauth.reset({ market: useRouteStore().market })

      if (routeStore.isSweden) {
        return await this.initiateEidSe()
      } else if (routeStore.isNorway) {
        return await this.initiateEidNo()
      } else if (routeStore.isFinland) {
        return await this.initiateEidFi()
      }
    },
    async initiateEidSe () {
      const formStore = useFormStore()
      const messagesStore = useMessagesStore()
      const miscStore = useMiscStore()
      const contentStore = useContentStore()

      const eidProvider = formStore.getForm?.provider as EidProviders

      if (miscStore.isDesktop) {
        this.mutateShowSecret(true)
      }

      if (eidProvider === contentStore.getContent?.EID_PROVIDERS.BANKID) {
        await messagesStore.setStatusMessage({
          group: 'bankid',
          name: miscStore.getIsDesktop ? 'start' : 'startMobile',
        })
        await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'bankid_start' })
      } else if (eidProvider === contentStore.getContent?.EID_PROVIDERS.FREJA) {
        await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'freja_start' })
      }

      this.mutateUserCancel(false)
      this.mutateLoading(true)

      try {
        // hotjarTagRecording('zmarta-header/eid/initiate')
        const { orderRef, autoStartToken } = await requests.serviceAuth.eid.initiate({ market: Market.Se, eidProvider })

        if (!orderRef || !autoStartToken) {
          await ZGA?.event.misc?.loginAttempt({ success: false })

          if (!orderRef) await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_order_ref' })
          if (!autoStartToken) await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_autostart_token' })

          await messagesStore.setModalMessage({ group: eidProvider ?? 'authentication' })

          this.mutateLoading(false)
          this.resetAuthentication()

          return false
        }

        this.mutateAuth({ token: orderRef, autoStartToken })
        await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        // hotjarTriggerEvent('zmarta-header/eid/initiate/success')
        return true
      } catch (e: any) {
        logError('authentication/initiateEidSe', { e })
        isAuthError(e)
          ? await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await messagesStore.setModalMessage({ group: eidProvider ?? 'authentication', code: e?.code || e?.context?.code })
        this.mutateLoading(false)
        this.resetAuthentication()
        // hotjarTriggerEvent('zmarta-header/eid/initiate/error')
        return false
      }
    },
    async initiateEidNo () {
      await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'signicat_start' })
      await useMessagesStore().setStatusMessage({
        group: 'authentication',
        name: 'loggingIn',
      })

      this.mutateLoading(true)

      try {
        // hotjarTagRecording('zmarta-header/eid/initiate')

        const ssn = useFormStore().getForm?.ssn
        const { orderRef, url } = await requests.serviceAuth.eid.initiate({ market: Market.No, ssn })

        if (!orderRef || !url) {
          await ZGA?.event.misc?.loginAttempt({ success: false })

          if (!orderRef) await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_order_ref' })
          if (!url) await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_url_error' })

          await useMessagesStore().setModalMessage({ group: 'authentication' })

          this.mutateLoading(false)
          this.resetAuthentication()

          return false
        }

        this.mutateAuth({ token: orderRef, verifyEid: true })
        await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })
        // hotjarTriggerEvent('zmarta-header/eid/initiate/success')

        return url
      } catch (e: any) {
        logError('authentication/initiateEidNo', { e })

        isAuthError(e)
          ? await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await useMessagesStore().setModalMessage({ group: 'authentication', code: e?.code || e?.context?.code })

        this.mutateLoading(false)
        this.resetAuthentication()

        // hotjarTriggerEvent('zmarta-header/eid/initiate/error')

        return false
      }
    },
    async initiateEidFi () {
      await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'signicat_start' })

      const formStore = useFormStore()
      const messagesStore = useMessagesStore()

      await messagesStore.setStatusMessage({
        group: 'authentication',
        name: 'loggingIn',
      })

      this.mutateLoading(true)

      try {
        const ssn = formStore.getForm?.ssn
        const { orderRef, url } = await requests.serviceAuth.eid.initiate({ market: Market.Fi, ssn })

        if (!orderRef || !url) {
          await ZGA?.event.misc?.loginAttempt({ success: false })

          if (!orderRef) await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_order_ref' })
          if (!url) await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_url_error' })

          await messagesStore.setModalMessage({ group: 'authentication' })

          this.mutateLoading(false)
          this.resetAuthentication()

          return false
        }

        this.mutateAuth({ token: orderRef, verifyEid: true })

        await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        // hotjarTriggerEvent('zmarta-header/eid/initiate/success')

        return url
      } catch (e: any) {
        logError('authentication/initiateEidFi', { e })

        isAuthError(e)
          ? await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await messagesStore.setModalMessage({
          group: 'authentication',
          code: e?.code ?? e?.context?.code,
        })

        this.mutateLoading(false)
        this.resetAuthentication()

        // hotjarTriggerEvent('zmarta-header/eid/initiate/error')

        return false
      }
    },
    async initiatePassword () {
      const formStore = useFormStore()
      const messagesStore = useMessagesStore()
      const customerStore = useCustomerStore()
      const routeStore = useRouteStore()

      await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'start' })

      this.resetAuthentication()
      await messagesStore.resetMessages()
      customerStore.resetCustomer()

      await requests.serviceAuth.oauth.reset({ market: routeStore.market })

      const ssn = formStore.getForm?.ssn
      const password = formStore.getForm?.password

      if (!ssn || !password) {
        if (!ssn) await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_ssn' })
        if (!password) await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_password' })

        await messagesStore.setModalMessage({ group: 'authentication' })

        this.mutateLoading(false)
        this.resetAuthentication()
        return false
      }

      await messagesStore.setStatusMessage({ group: 'authentication', name: 'loggingIn' })
      this.mutateLoading(true)

      try {
        // hotjarTagRecording('zmarta-header/password/initiate')

        const res = await requests.serviceAuth.password.initiate({
          market: routeStore.market,
          ssn,
          password,
        })

        if (!res.orderRef) {
          await ZGA?.event.misc?.loginAttempt({ success: false, method: 'password' })

          await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_order_ref' })
          await messagesStore.setModalMessage({ group: 'authentication' })

          this.mutateLoading(false)
          this.resetAuthentication()
          return false
        }

        this.mutateAuth({ token: res.orderRef })
        await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        // hotjarTriggerEvent('zmarta-header/password/initiate/success')
        return true
      } catch (e: any) {
        logError('authentication/initiatePassword', { e })
        isAuthError(e)
          ? await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await ZGA?.event.misc?.loginAttempt({ success: false, method: 'password' })

        await messagesStore.setModalMessage({ group: 'authentication', code: e?.code || e?.context?.code })
        this.mutateLoading(false)
        this.resetAuthentication()
        // hotjarTriggerEvent('zmarta-header/password/initiate/error')
        return false
      }
    },
    async secretEid () {
      const routeStore = useRouteStore()

      if (routeStore.isSweden) {
        return await this.secretEidSe()
      } else if (routeStore.isNorway) {
        return await this.secretEidNo()
      } else if (routeStore.isFinland) {
        return await this.secretEidFi()
      }
    },
    async secretEidSe () {
      const token = this.token ?? null

      if (!token) {
        await metrics.password_initiate.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })
        await metrics.eid_secret.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_token' })

        return false
      }

      try {
        const routeStore = useRouteStore()
        const { secret } = await requests.serviceAuth.eid.secret({ market: routeStore.market, token })

        this.mutateAuth({ secret })
        return true
      } catch (e: any) {
        logError('authentication/secretEidSe', { e })
        isAuthError(e)
          ? await metrics.eid_secret.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_secret.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        // hotjarTriggerEvent('zmarta-header/eid/secret/error')
        return false
      }
    },
    async secretEidNo () {
      return false
    },
    async secretEidFi () {
      return false
    },
    async collectEid () {
      const routeStore = useRouteStore()

      if (routeStore.isSweden) {
        return await this.collectEidSe()
      } else if (routeStore.isNorway) {
        return await this.collectEidNo()
      } else if (routeStore.isFinland) {
        return await this.collectEidFi()
      }
    },
    async collectEidSe () {
      const formStore = useFormStore()
      const messagesStore = useMessagesStore()

      this.mutateLoading(true)

      if (window.location.hash) {
        history.pushState('', document.title, window.location.pathname)
      }

      const token = this.token
      const eidProvider = formStore.getForm?.provider

      if (!token) {
        await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_token' })

        this.mutateLoading(false)
        this.mutateUserCancel(false)
        this.resetAuthentication()
        return false
      }

      try {
        const { status, hintCode } = await requests.serviceAuth.eid.collect({ market: Market.Se, token })

        if (status === 'failed') {
          await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'failed' })

          this.mutateLoading(false)
          this.resetAuthentication()
          return false
        }

        if (status === 'pending' && hintCode === 'userCancel') {
          await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'cancel' })
          await messagesStore.setModalMessage({ group: eidProvider ?? 'authentication', name: 'eidCanceled' })

          this.mutateLoading(false)
          this.mutateUserCancel(true)
          this.resetAuthentication()
          return false
        }

        return await this.handleCollect({ status, hintCode })
      } catch (e: any) {
        logError('authentication/collectEidSe', { e })
        isAuthError(e)
          ? await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await messagesStore.setModalMessage({ group: eidProvider ?? 'authentication', code: e?.code || e?.context?.code })
        this.mutateLoading(false)
        this.resetAuthentication()
        return false
      }
    },
    async collectEidNo () {
      if (!this.verifyEid) return false

      this.mutateLoading(false)
      emitter.emit('login:show')

      this.mutateAuth({ verifyEid: false })

      if (this.success) {
        this.mutateLoading(false)
        return false
      }

      const token = this.token
      if (!token) {
        await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_token' })

        await useMessagesStore().setModalMessage({
          group: 'authentication',
        })

        this.mutateLoading(false)
        this.resetAuthentication()

        return false
      }

      try {
        const { status, hintCode } = await requests.serviceAuth.eid.collect({ market: Market.No, token })
        return await this.handleCollect({ status, hintCode })
      } catch (e: any) {
        logError('authentication/collectEidNo', { e })
        isAuthError(e)
          ? await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await useMessagesStore().setModalMessage({
          group: 'authentication',
          code: e?.code || e?.context?.code,
        })

        this.mutateLoading(false)
        this.resetAuthentication()

        return false
      }
    },
    async collectEidFi () {
      if (!this.verifyEid) return false

      this.mutateLoading(false)
      emitter.emit('login:show')

      this.mutateAuth({ verifyEid: false })

      if (this.success) {
        this.mutateLoading(false)
        return false
      }

      const token = this.token
      if (!token) {
        await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_token' })

        await useMessagesStore().setModalMessage({
          group: 'authentication',
        })

        this.mutateLoading(false)
        this.resetAuthentication()

        return false
      }

      try {
        const { status, hintCode } = await requests.serviceAuth.eid.collect({ market: Market.Fi, token })
        return await this.handleCollect({ status, hintCode })
      } catch (e: any) {
        logError('authentication/collectEidFi', { e })
        isAuthError(e)
          ? await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await useMessagesStore().setModalMessage({
          group: 'authentication',
          code: e?.code || e?.context?.code,
        })

        this.mutateLoading(false)
        this.resetAuthentication()

        return false
      }
    },
    async handleCollect ({ status, hintCode }: { status: string, hintCode: string }) {
      const formStore = useFormStore()
      const messagesStore = useMessagesStore()

      const eidProvider = formStore.getForm?.provider

      this.status = status
      this.hintCode = hintCode

      if (status === 'complete') {
        await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        // hotjarTriggerEvent('zmarta-header/eid/collect/complete')
        return true
      } else if (status === 'failed') {
        if (hintCode === 'startFailed') {
          await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'start_failed' })

          // hotjarTriggerEvent('zmarta-header/eid/collect/failed')
        } else {
          await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'failed' })

          // hotjarTriggerEvent('zmarta-header/eid/collect/failed')
        }

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await messagesStore.setModalMessage({
          group: eidProvider ?? 'authentication',
          name: hintCode,
        })

        this.mutateLoading(false)
        this.resetAuthentication()
        return false
      } else if (status === 'no-customer') {
        await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_customer' })

        // hotjarTriggerEvent('zmarta-header/eid/collect/no-customer')

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await messagesStore.setModalMessage({
          group: 'authentication',
          name: 'noCustomer',
        })

        this.mutateLoading(false)
        this.resetAuthentication()
        return false
      } else if (!status) {
        await metrics.eid_collect.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'unknown' })

        // hotjarTriggerEvent('zmarta-header/eid/collect/unknown')

        await ZGA?.event.misc?.loginAttempt({ success: false })

        await messagesStore.setModalMessage({
          group: 'authentication',
        })

        this.mutateLoading(false)
        this.resetAuthentication()
        return false
      } else {
        if (hintCode !== 'outstandingTransaction') {
          this.showSecret = false
        }

        let group = eidProvider ?? 'authentication'
        let name = hintCode

        const routeStore = useRouteStore()

        if (routeStore.isSweden && !useMiscStore().getIsDesktop) {
          name = `${name}Mobile`

          if (['userSign', 'started'].includes(hintCode)) {
            group = 'authentication'
            name = 'confirming'
          }
        }

        await messagesStore.setStatusMessage({
          group,
          name,
        })

        return false
      }
    },
    async cancelEid () {
      const routeStore = useRouteStore()

      if (routeStore.isSweden) {
        await this.cancelEidSe()
      } else if (routeStore.isNorway) {
        await this.cancelEidNo()
      } else if (routeStore.isFinland) {
        await this.cancelEidFi()
      }
    },
    async cancelEidSe () {
      const formStore = useFormStore()
      const messagesStore = useMessagesStore()

      const token = this.token ?? null
      const eidProvider = formStore.getForm?.provider

      if (!token) {
        await metrics.eid_cancel.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_token' })

        this.mutateLoading(false)
        this.resetAuthentication()
        return false
      }

      try {
        this.mutateLoading(false)
        this.mutateUserCancel(true)
        this.resetAuthentication()

        await requests.serviceAuth.eid.cancel({ market: Market.Se, token })
        await messagesStore.setModalMessage({ group: 'authentication', name: 'userForceCancel' })
        await metrics.eid_cancel.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        // hotjarTriggerEvent('zmarta-header/eid/cancel/success')
        return true
      } catch (e: any) {
        logError('authentication/cancelEidSe', { e })
        isAuthError(e)
          ? await metrics.eid_cancel.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.eid_cancel.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await messagesStore.setModalMessage({ group: eidProvider ?? 'authentication', code: e?.code || e?.context?.code })
        this.mutateLoading(false)
        this.resetAuthentication()
        return false
      }
    },
    async cancelEidNo () {
      return false
    },
    async cancelEidFi () {
      return false
    },
    async createOauth (type = 'eid'): Promise<boolean> {
      if (isLoggedIn()) return true

      const messagesStore = useMessagesStore()
      const customerStore = useCustomerStore()
      const formStore = useFormStore()

      await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'start' })

      const token = this.token

      if (!token) {
        await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'no_token' })

        await messagesStore.setModalMessage({ group: 'authentication' })
        this.mutateLoading(false)
        this.resetAuthentication()

        await ZGA?.event.misc?.loginAttempt({ success: false })
        return false
      }

      await messagesStore.setStatusMessage({ group: 'authentication', name: 'confirming' })
      this.mutateLoading(true)

      try {
        const contentStore = useContentStore()
        const routeStore = useRouteStore()

        await requests.serviceAuth.oauth.create({ market: routeStore.market, token })

        this.$patch({
          success: true,
          token: null,
          autoStartToken: null,
          showSecret: false,
          secret: null,
          status: null,
          hintCode: null,
        })

        await customerStore.fetchCustomer()

        emitter.emit('login:close')
        window.dispatchEvent(new window.Event('zmarta-header:logged-in'))

        await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        if (type === 'eid') {
          await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'eid_success' })

          const eidProvider = formStore.getForm?.provider as EidProviders

          if (eidProvider === contentStore.getContent?.EID_PROVIDERS.BANKID) {
            await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'eid_bankid_success' })
          } else if (eidProvider === contentStore.getContent?.EID_PROVIDERS.FREJA) {
            await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'eid_freja_success' })
          } else if (routeStore.isNorway ?? routeStore.isFinland) {
            await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'eid_signicat_success' })
          }
        } else if (type === 'password') {
          await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'password_success' })
        }

        await ZGA?.event.misc?.loginAttempt({ success: true, method: type as ProviderTypes })
        // hotjarTriggerEvent('zmarta-header/oauth/create/success');

        return true
      } catch (e: any) {
        logError('authentication/createOauth', { e })
        isAuthError(e)
          ? await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.oauth_create.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await ZGA?.event.misc?.loginAttempt({ success: false, method: type as ProviderTypes })

        await messagesStore.setModalMessage({ group: 'authentication', code: e?.code || e?.context?.code })
        this.mutateLoading(false)
        this.resetAuthentication()

        // hotjarTriggerEvent('zmarta-header/oauth/create/error');

        return false
      }
    },
    async logout (): Promise<void> {
      const messagesStore = useMessagesStore()

      await messagesStore.setStatusMessage({
        group: 'authentication',
        name: 'loggingOut',
      })

      this.mutateLoading(true)

      try {
        await requests.serviceAuth.oauth.reset({ market: useRouteStore().market })

        this.resetAuthentication()
        await this.autoLogout()

        await metrics.logout.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })
        // hotjarTriggerEvent('zmarta-header/success')

        emitter.emit('logout:redirect')
      } catch (e: any) {
        isAuthError(e)
          ? await metrics.logout.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.logout.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        await messagesStore.setModalMessage({
          group: 'authentication',
          code: (e?.code || e?.context?.code) as string,
        })

        this.mutateLoading(false)
        this.resetAuthentication()

        // hotjarTriggerEvent('zmarta-header/error')

        emitter.emit('login:close')
      }
    },

    async autoLogout () {
      const customerStore = useCustomerStore()
      const formStore = useFormStore()
      const loaderStore = useLoaderStore()

      try {
        customerStore.$reset()

        formStore.resetField({ field: 'ssn' })
        formStore.resetField({ field: 'password' })
        await loaderStore.hideLoader()
        await metrics.auto_logout.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'success' })

        /// hotjarTriggerEvent('zmarta-header/auto-logout/success');
      } catch (e) {
        isAuthError(e)
          ? await metrics.auto_logout.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error_auth' })
          : await metrics.auto_logout.inc({ brand: useRouteStore()?.brand, market: useRouteStore()?.market, label: 'error' })

        // hotjarTriggerEvent('zmarta-header/auto-logout/error');
      }
    },
    resetLoading () {
      this.mutateLoading(false)
    },
    resetAuthentication () {
      this.$reset()
    },
    mutateAuth (payload: Partial<AuthenticationState>) {
      if ('success' in payload) this.success = payload.success!
      if ('token' in payload) this.token = payload.token as string | null
      if ('autoStartToken' in payload) this.autoStartToken = payload.autoStartToken as string | null
      if ('secret' in payload) this.secret = payload.secret as string | null
      if ('loading' in payload) this.loading = payload.loading!
      if ('verifyEid' in payload) this.verifyEid = payload.verifyEid!
      if ('status' in payload) this.status = payload.status as string | null
      if ('hintCode' in payload) this.hintCode = payload.hintCode as string | null
    },
    mutateUserCancel (cancel: boolean) {
      this.userCancel = cancel
    },
    mutateLoading (loading: boolean) {
      this.loading = loading
    },
    mutateShowSecret (showSecret: boolean) {
      this.showSecret = showSecret
    },
  },
  persist: !import.meta.env.SSR
    ? {
        storage: sessionStorage,
      }
    : undefined,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthenticationStore, import.meta.hot))
}
