import type { Market } from '@ocp-zmarta/content-sdk'
import { serviceAuthHeaders } from '@/utils'
import { request } from '@/requests/request'

/**
 * @param {string} market
 * @param {string|null} token
 * @returns {Promise<any>}
 */
export async function secret ({ market, token }: { market: Market, token: string }): Promise<any> {
  return await request({
    method: 'GET',
    url: `/service/auth/api/v1/eid/secret/${token}`,
    headers: serviceAuthHeaders(market),
  })
}
